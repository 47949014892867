// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MainLayout from './pages/MainLayout'; // New MainLayout to handle routing logic
import './css/App.css';

function App() {
  return (
    <Router>
      <MainLayout /> 
    </Router>
  );
}

export default App;
