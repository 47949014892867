
import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function RSVPContent() {
  return (
    <div className='text-container'>
      <header>
        <p>
        <Title style={{ color: '#d3d3d3' }} level={3}>Invitations will be mailed in the coming months!</Title>
        </p>
      </header>
    </div>
  );
}

export default RSVPContent;