import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function ScheduleContent() {
  return (
    <div className='text-container'>
      <header>
        <p>
          <Title style={{ color: '#d3d3d3' }} level={3}>
            6PM Sept 27, 2025 - Ceremony @{' '}
            <a 
              href='https://maps.app.goo.gl/B3dghXhiZM4oSV3u7' 
              style={{ color: '#d3d3d3', textDecoration: 'none' }} 
              target="_blank" 
              rel="noopener noreferrer"
            >
              Olympic Sculpture Park Event Center
            </a>
          </Title>
        </p>
      </header>
    </div>
  );
}

export default ScheduleContent;
