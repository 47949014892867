
import React from 'react';
import '../../css/App.css';
import { Typography } from 'antd';

const { Title } = Typography;

function HomeContent() {
  return (
    <div className='text-container'>
        <Title style={{ color: '#d3d3d3' }}>Harry and Monika</Title>
        <Title style={{ color: '#d3d3d3' }} level={3}>September 27, 2025</Title>
        <Title style={{ color: '#d3d3d3'}} level={4}>Seattle Wa</Title>
    </div>
  );
}

export default HomeContent;