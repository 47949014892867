
import '../../css/App.css';

function StayContent() {
  return (
    <div className='text-container'>
      <header>
        <p>
          Coming Soon!
        </p>
      </header>
    </div>
  );
}

export default StayContent;